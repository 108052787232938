import service from '@/required/axios'
let $environment = 'web'
// let $environment = 'android'
// let $environment = 'ios'

// 店铺域名
let domian = '';
if ($environment === 'android') {
  const { data, code } = await service({
    url: 'system.version',
    method: "get",
  })
  if (code == 200) {
    domian = data.domain;
  }
} else {
  domian = window.location.hostname.split('.').slice(-2).join('.');
}
let storeHost = 'https://seller.' + domian;

/**
 *
 * 跳转地址
 *
 * @param url
 * @returns {boolean}
 */
function $openUrl (url) {
  if ($environment === 'android') {
    plus.runtime.openURL(url)
    return true;
  }
  window.location.href = url
  return true;
}

/**
 * 是否展示
 *
 * @returns {boolean}
 */
function $showContent () {
  return $environment === 'web';
}

function $JumpShops (params = '') {
  let languageType = window.localStorage.getItem('languageType') || ''
  let code = window.localStorage.getItem('code') == null ? '' : window.localStorage.getItem('code')
  let jumpUrl =storeHost + '?language=' + languageType + '&' + 'code=' + code + params + '';
  if (!params.includes('?')) {
    jumpUrl = jumpUrl + '?';
  }
  $openUrl(jumpUrl + '&code=' + code + '&language=' + languageType);
  return true;
}
export default {
  $environment,
  $JumpShops,
  $openUrl,
  $showContent
}