// 安卓app下载地址
const downloadAndroidAppUrl = 'https://source.omm250.com/download/tkline.shop.apk'
// 苹果app下载地址
const downloadIosAppUrl = 'https://source.omm250.com/download/tkline.shop.mobileconfig'
const baseUrl = 'https://api.omm250.com/mall_v250'// api请求
const imgUrl = 'https://source.omm250.com/' // 图片域名
let count=1
// 语言转换
const lang = {
  null: "english",
  English: "english",
  日本語: "japanese",
  Português: "portuguese",
  繁體中文: "chineseTraditional",
  Français: "french",
  Deutsch: "german",
  Türk: "turkish",
  Español: "spanish",
  عربي: "arabic",
  한국인: 'korean',
  Suomi: 'finland',
  Русский: 'russian',
  italiano: 'italian',
  แบบไทย: 'thai',
  Nederlands: 'dutch',
}

/*******************************************************************/
// 店铺注册页
const shopRegisterWebUrl = '#/join-index?roteType=1'

// 店铺登录页
const shopLoginWebUrl = '#/login/loginIndex'

export default {
  imgUrl,
  baseUrl,
  lang,
  count,
  downloadAndroidAppUrl,
  downloadIosAppUrl,
  shopRegisterWebUrl,
  shopLoginWebUrl

}
