import Vue from 'vue'
import { languageList } from '@/utils/language/language'

import {
  Toast
} from "vant";
let messageLanguage
import axios from 'axios'
import baseUrl from '@/utils/projectConfig'
const ServerRequestUrl = baseUrl.baseUrl
const service = axios.create({
  baseURL: ServerRequestUrl
})
Vue.nextTick(() => {
  messageLanguage = languageList('messageLanguage').loadingTitle
})
window.addEventListener("_countNum", (val) => {
  messageLanguage = languageList('messageLanguage').loadingTitle
});

let loadingCount = 0
let rest
// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  let url = window.location.hash
  // let str =''
  // rest = str.test(url)
  let rest = false
  config.headers.Authorization = window.localStorage.getItem('authenticationToKen_');
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  if (!rest) {
    loadingCount += 1
  }
  if (loadingCount === 1 && !rest) {
    Toast.loading({
      forbidClick: true,
      duration: 0,
      message: messageLanguage + '...'
    })
  }
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})


// respone拦截器==>对响应做处理
service.interceptors.response.use(
  response => {
    // 成功请求到数据
    // 这里根据后端提供的数据进行对应的处理 
    if (response.data.code === 200) {
      loadingCount--
      if (loadingCount == 0 && !rest) {
        setTimeout(() => {
          Toast.clear()
        }, 500)
      }
    } else if (response.data.code == 1002) {
      Toast.fail({
        forbidClick: true,
        duration: 2500,
        message: response.data.message
      })
      sessionStorage.clear();
      localStorage.clear()
    } else if (response.data.code == 400) {
      Toast.fail({
        forbidClick: true,
        duration: 2500,
        message: response.data.message
      })
      sessionStorage.clear();
      localStorage.clear()
    } else {
      Toast.fail({
        forbidClick: true,
        duration: 2500,
        message: response.data.message
      })
      sessionStorage.clear();
      localStorage.clear()
    }
    return response.data
  },
  error => {
    Toast.clear()
    let text = JSON.parse(JSON.stringify(error))
    Toast.fail({
      forbidClick: true,
      duration: 2500,
      message: text.message
    })

    return Promise.reject(error)
  }
)
export default service