<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  created () {
    sessionStorage.setItem('moneyIcon', '$')
    let code = this.GetParam(window.location.href, 'code')
    let language = this.GetParam(window.location.href, 'language')
    language = decodeURI(language)
    if (language && language !== 'null' && language !== 'undefined') {
      localStorage.setItem('languageType', language)
    }
    let query = this.$route.query
    if (Object.values(query).length > 0 && query.code) {
      localStorage.setItem('code', query.code)
    } else if (code == 0 || code) {
      localStorage.setItem('code', code)
    }
    this.$forceUpdate()
  },
  methods: {
    /**
     *  @desc  获取url参数
     *  @param  {String} url为穿过来的链接
     *  @param  {String} id为参数名
     */
    GetParam (url, id) {
      url = url + ''
      let regstr = '/(\\?|\\&)' + id + '=([^\\&]+)/'
      let reg = eval(regstr)
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg)

      if (result && result[2]) {
        return result[2].split('#')[0]
      }
    },
  },
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
}

body {
  max-width:768px;
  margin: auto !important;
}

.van-tabs__wrap{
  max-width:768px;
  margin: auto !important;
}
::-webkit-scrollbar {
  display: none;
}

.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
  font-size: 32px;
  font-weight: 900;
  width: 38px !important;
  height: 38px !important;
}

.el-loading-spinner .path {
  stroke: $blur !important;
  stroke-width: 5px !important;
}
</style>
